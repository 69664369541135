<template>
  <div class="primary-data padding-top-layout padding-right-layout list-menu">
    <div class="list-menu--title">
      <page-title :title="titlePage" />
    </div>
    <p class="list-menu--title">{{ descriptionPage }}</p>
    <div class="listMenus-btn">
      <div v-if="isCfpSupplierValid">
        <div class="menu-item" v-if="!isProduct">
        <div class="title-btn">
          <span>{{ $t('supplier.label_target_date') }}</span>
          <div class="title-require">{{ $t('a_export_report.label_required') }}</div>
        </div>
        <div class="listMenu-btn">
          <div class="listMenu-btn-text option-duration">
            <v-select
              solo
              flat
              :items="yearList"
              v-model="dataPrimary.selectedYear"
              :label="labelSelectYear"
              class="select-type list-menu--select select-item"
              :menu-props="{ contentClass: 'select-menu export-select' }"
              @change="handleCheckInputDuration"
              id="select-year"
            ></v-select>
            <v-select
              solo
              flat
              :items="monthList"
              v-model="dataPrimary.selectedMonth"
              :label="labelSelectMonth"
              class="select-type list-menu--select select-item"
              :menu-props="{ contentClass: 'select-menu export-select' }"
              @change="handleCheckInputDuration"
              id="select-month"
            ></v-select>
          </div>
        </div>
      </div>
      <div class="menu-item" v-else>
        <div class="title-btn">
          <span>{{ $t('supplier_products.title_select_form') }}</span>
          <!-- <div class="title-require">{{$t('b_export_report.label_required')}}</div> -->
        </div>
        <div class="listMenu-btn">
          <div class="listMenu-btn-text option-duration">
            <input-field :label="$t('register_reduction.placeholder_input_title')"
                v-model="dataPrimary.title" :inputValue="dataPrimary.title" :isReadOnly="true" id="title" />
          </div>
        </div>
      </div>
      </div>
      <div class="menu-item">
        <div class="title-btn title-btn-select-scope">
          <span>{{ $t('supplier.label_notes_explanation') }}</span>
        </div>
        <div class="listMenu-btn-text">
          <input-field
            class="mt-2"
            :label="$t('supplier.placeholder_notes_description')"
            :inputValue="dataPrimary.note"
            @blur="handleBlur"
            v-model="dataPrimary.note"
            id="note"
          />
        </div>
      </div>
    </div>
    <div ref="scrollElement">
      <div class="return__button-responsive" :class="{ 'fixed-button-bottom': isFixedButton }">
        <div class="list-menu-registration">
          <common-button
            class="submit-register-btn"
            :label="labelSubmitSelect"
            :disabled="validateData()"
            type="colored"
            @action="submitRegisterData()"
            v-ripple="false"
          />
        </div>
      </div>
    </div>
    <router-link
      class="inquiries-regarding"
      :to="{ path: routes.CONTACT_SUPPLIER, query: { primary_uuid: $route.query.primary_uuid } }"
    >
      <div class="inquiries-regarding__text">
        {{ $t('supplier.hyperlink_inquiries_regarding_carbonix') }}
        <span class="underline"></span>
      </div>
    </router-link>
    <!-- <error-popup :dialog="errorDialog" :message="errorMessage" @submit="handleClose" /> -->
    <notification-popup
      :dialog="alertNotify"
      :message="$t(messageNotifySuccess)"
      @submit="alertNotify = false"
      confirmText="OK"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { ROUTES } from '@/router/constants';
import PageTitle from '@/components/pageTitle/pageTitle';
import CommonButton from '@/components/utils/button.vue';
import ExportPulldown from '@/components/pulldown/exportReport';
import ErrorPopup from '@/components/dialogs/error-popup.vue';
import { mapState } from 'vuex';
import InputField from '@/components/products/input/InputField.vue';
import { getStartMonth } from '@/api/duration';
import { getMonthListCustome, calcYearFromStartMonthCustome } from '@/utils/registerData';
import NotificationPopup from '@/components/dialogs/notification-popup.vue';
import { infoFormPrimaryDataApi } from '@/api/supplier';

export default {
  name: 'FormRegister',
  components: {
    PageTitle,
    CommonButton,
    ExportPulldown,
    ErrorPopup,
    InputField,
    NotificationPopup,
  },
  data() {
    return {
      breadcrumb: [
        {
          text: this.$t('supplier.title_primary_submission_form'),
          disabled: true,
          href: ROUTES.SUPPLIER,
        },
      ],
      dataPrimary: {
        selectedYear: null,
        selectedMonth: null,
        note: null,
        title: null,
      },

      yearList: [],
      monthList: [],
      isFixedButton: false,
      startMonth: null,
      alertNotify: false,
      routes: ROUTES,
      listYearSetting: null,
      infoFormPrimaryData: {},
    };
  },
  async mounted() {
    this.checkBreadCrumbSupplier();
    await Promise.all([this.getDuration(), this.getInfoFormPrimaryDataApi()]);
    window.addEventListener('scroll', this.handleScroll);
    this.handleCheckIsRegisteredDataSuccess();
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    ...mapActions('commonApp', ['updateBreadCrumb']),
    ...mapActions('supplier', ['setDataInfo', 'setStatusDataSupplier']),
    async getInfoFormPrimaryDataApi() {
      await infoFormPrimaryDataApi(this.$route?.query?.primary_uuid)
        .then((res) => {
          this.infoFormPrimaryData = res.data;
          this.dataPrimary.title = this.infoFormPrimaryData?.title;
        })
        .catch(() => {
          // redirect to home if role not suplier & 404 if role suplier
          const ROLE_SUPLIER = 6;
          if (res.user.role_id === ROLE_SUPLIER) {
            this.$router.push('/404');
          } else {
            this.$router.push({ path: ROUTES.TYPE_SELECTION });
          }
        });
    },
    async getDuration() {
      await getStartMonth(this.$store.state.userData.contractor).then((res) => {
        this.startMonth = res.data.start_month;
        this.yearList = calcYearFromStartMonthCustome(res.data.start_month, 2017);
        this.monthList = getMonthListCustome(null, res.data.start_month);
      });
    },
    submitRegisterData() {
      const dataSubmit = {
        selectedYear: this.dataPrimary.selectedYear,
        selectedMonth: this.dataPrimary.selectedMonth,
        note: this.dataPrimary.note,
      };
      this.setDataInfo(dataSubmit);
      this.$router.push({
        path: this.isProduct ? ROUTES.REGISTER_PRIMARY_PRODUCT_DATA : ROUTES.REGISTER_PRIMARY_DATA,
        query: { primary_uuid: this.$route?.query?.primary_uuid },
      });
    },
    handleCheckInputDuration() {},
    validateData() {
      return this.isProduct
        ? !this.dataPrimary.title
        : !this.dataPrimary.selectedYear || !this.dataPrimary.selectedMonth;
    },
    handleScroll() {
      const scrollElementTop = this.$refs.scrollElement?.offsetTop;
      const scrollPosition = window.scrollY + window.innerHeight;
      if (scrollPosition > scrollElementTop + 190) {
        this.isFixedButton = false;
      } else {
        this.isFixedButton = true;
      }
    },
    handleCheckIsRegisteredDataSuccess() {
      const isRegistered = this.isRegisteredSucceeded;
      if (isRegistered) {
        this.alertNotify = true;
        this.setStatusDataSupplier(false); // reset status data supplier
      }
    },

    handleBlur() {
      if(this.dataPrimary.note && this.dataPrimary.note?.length > 255) {
        this.dataPrimary.note = this.dataPrimary.note.slice(0, 255)
      }
    },
    checkBreadCrumbSupplier() {
      this.breadcrumb = [
        {
          text: this.isProduct
            ? this.$t('supplier_products.title_emission_data_submissions_form')
            : this.$t('supplier.title_primary_submission_form'),
          disabled: true,
          href: ROUTES.SUPPLIER,
        },
      ];
      this.updateBreadCrumb(this.breadcrumb);
    },
  },
  computed: {
    ...mapState('commonApp', ['breadCrumbText', 'isLoadingCommon']),
    ...mapState('userData', ['isSettingPcaf', 'planType', 'isCfpSupplier']),
    ...mapState('supplier', ['isRegisteredSucceeded']),
    labelSelectMonth() {
      return this.$t('list_menu.placeholder_select_month');
    },
    labelSelectYear() {
      return this.$t('list_menu.placeholder_select_year');
    },
    titlePage() {
      return this.isProduct
          ? this.$t('supplier_products.title_emission_data_submissions_form')
          : this.$t('supplier.title_primary_submission_form')
    },
    descriptionPage() {
      return this.isProduct
          ? this.$t('supplier_products.description_emission_data_submissions_form')
          : this.$t('supplier.description_select_month_year')
    },
    labelSubmitSelect() {
      return this.$t('supplier.button_submit_select_month_year');
    },  
    isProduct() {
      return this.isCfpSupplier;
    },
    messageNotifySuccess() {
      return this.isCfpSupplier
        ? 'supplier_products.description_notify_success_data'
        : 'supplier.description_notify_select_month_year';
    },
    isCfpSupplierValid() {
      return this.isCfpSupplier !== null && this.isCfpSupplier !== undefined;
    }
  },
  watch: {
    'dataPrimary.selectedYear': {
      handler: function (value) {
       
        this.monthList = getMonthListCustome(value, this.startMonth);
        if (
          this.dataPrimary.selectedMonth &&
          this.monthList.findIndex((item) => item.value === this.dataPrimary.selectedMonth) < 0
        ) {
          this.dataPrimary.selectedMonth = '';
        }
      },
      deep: true,
    },
    isProduct: {
      handler() {
       this.checkBreadCrumbSupplier();
      },
      deep: true,
    }
  },
};
</script>
<style lang="scss" scoped>
.primary-data {
  position: relative;
  .list-menu-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px;
    width: 100%;
  }
  .list-menu--title {
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 28px;
    margin-bottom: 48px;
    letter-spacing: 0.05em;
    color: $monoBlack;
    flex: none;
    order: 1;
    flex-grow: 0;
  }

  .listMenu-input-emission {
    display: flex;
    gap: 8px;
    flex-flow: column;
    justify-content: center;
    padding: unset;
    .plus-emission {
      display: flex;
      height: 40px;
      padding: 7px 16px 9px 16px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      align-self: stretch;
      border-radius: 4px;
      border: 1px dashed $goldMid;
      overflow: hidden;
      color: $goldMid;
      text-align: center;
      text-overflow: ellipsis;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.42px;
      cursor: pointer;
    }
    .input-emission {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.42px;
      .index-number {
        color: $monoBlack;
      }
      .unit {
        color: $monoMid;
        white-space: nowrap;
      }
      .icon-attach {
        display: flex;
        padding: 6px 8px;
        align-items: center;
        gap: 16px;
        border-radius: 4px;
        border: 1px solid $goldMid;
        box-shadow: 0px 2.195px 3.99px 0px rgba(0, 0, 0, 0.07), 0px 7.371px 13.402px 0px rgba(0, 0, 0, 0.04),
          0px 33px 60px 0px rgba(0, 0, 0, 0.03);
        cursor: pointer;
      }
    }
  }
  .listMenus-btn {
    display: flex;
    flex-flow: column;
    padding: 15px 20px;
    background: #e3eded;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    gap: 24px;
    .listMenu-btn {
      text-decoration: none;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      border-radius: 4px;
      margin-top: 8px;
      flex-direction: column;

      .listMenu-btn-text {
        width: 100%;
        align-items: center;

        .v-select {
          width: 100%;
        }
      }
    }
    .title-btn {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
      padding: 0px;
      margin: 0;
      gap: 16px;
      width: 100%;
      height: 24px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.03em;
      color: $monoBlack;
      .title-require {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0px 4px 2px;
        width: auto;
        height: 20px;
        margin-top: 8px;

        background: $goldMid;
        border-radius: 4px;
        color: white;
        font-size: 11px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.03em;
      }
    }
  }
  .inquiries-regarding {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 48px;
    align-self: stretch;
    color: $goldMid;
    cursor: pointer;
    margin: 40px auto 0 auto;
    width: fit-content;
    &__text {
      position: relative;
      .underline {
        position: absolute;
        left: 0;
        width: 0;
        height: 1px;
        background-color: $goldMid;
        transition: width 0.3s ease;
        bottom: -2px;
      }

      &:hover {
        text-decoration: underline;

        .underline {
          width: 100%;
        }
      }
    }
  }
  .list-menu-registration {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 32px;
    gap: 16px;
    height: 104px;
    background: $bgLight;
    border-top: 1px solid rgba(121, 134, 134, 0.12);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    .common-btn {
      width: 100%;

      .v-btn__content {
        color: $monoWhite;
      }
    }
  }
}
.registration-responsive {
  width: 90%;
  margin: auto;
  padding: 16px 0;
  .common-btn {
    width: 100%;
  }
}
.option-duration {
  display: flex;
  flex-flow: row !important;
  justify-content: space-between;
  gap: 16px;
}
.return__button-responsive {
  &.fixed-button-bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
    top: auto;
    padding: 0;
    width: 100%;
  }
}
@include desktop {
  .return__button-responsive {
    &.fixed-button-bottom {
      bottom: 0;
      left: -20px;
      right: 0;
      z-index: 99;
      top: auto;
      padding: 16px 20px 0px 20px;
      width: 100%;
    }
  }
  .primary-data {
    .listMenus-btn {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      max-width: 560px;
      margin: auto;
      padding: 32px 32px 48px 32px;
      .listMenu-btn {
        display: flex;
        flex-flow: column;
        justify-content: center;
        margin-top: 8px;
        padding: unset;
        &:hover {
          cursor: pointer;
        }
        .listMenu-btn-text {
          display: flex;
          flex-flow: column;
        }
      }
    }
    .submit-register-btn {
      // background: $goldMid !important;
      box-shadow: 0px 1.2365612983703613px 1.1335145235061646px 0px rgba(160, 181, 186, 0.24),
        0px 3.12735652923584px 2.866743326187134px 0px rgba(160, 181, 186, 0.17),
        0px 6.379513740539551px 5.8478875160217285px 0px rgba(160, 181, 186, 0.13),
        0px 13.140592575073242px 12.04554271697998px 0px rgba(160, 181, 186, 0.11),
        0px 36px 33px 0px rgba(160, 181, 186, 0.07);
      border-radius: 4px;
      transition: 0.1s ease-out;
    }
    .list-menu--title {
      padding-left: 40px;
    }
    .list-menu-registration {
      width: 100%;
      max-width: calc(560px);
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 32px;
      gap: 16px;
      height: 104px;
      background: $bgLight;
      border-top: 1px solid rgba(121, 134, 134, 0.12);
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;

      .common-btn {
        width: 100%;

        .v-btn__content {
          color: $monoWhite;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.listMenu-btn-text {
  .v-text-field.v-text-field--solo .v-label {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: $monoMid;
  }
}
.v-list-item .v-list-item__title {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.03em;
  color: $monoBlack;
}
.listMenu-scope .v-btn__content {
  color: $monoMid;
  height: max-content;
  max-width: 100%;
}
.listMenu-scope.has-scope .v-btn__content {
  color: $monoBlack;
}
.listMenu-scope .v-btn__content div {
  word-break: break-word;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.select-type.select-item .v-select__selection {
  color: $monoBlack;
}
.primary-data {
  .theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
    background: $monoOffWhite;
  }
  .v-input.field .v-input__control .v-input__slot {
    box-shadow: 0px 1.237px 1.134px 0px rgba(160, 181, 186, 0.24), 0px 3.127px 2.867px 0px rgba(160, 181, 186, 0.17),
      0px 6.38px 5.848px 0px rgba(160, 181, 186, 0.13), 0px 13.141px 12.046px 0px rgba(160, 181, 186, 0.11),
      0px 36px 33px 0px rgba(160, 181, 186, 0.07) !important;
  }
}
</style>
